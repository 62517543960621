import * as React from "react";
import { Link, graphql } from "gatsby";
import {
  Layout,
  SEO,
  CJournalJumbotron,
  CBreadCrumb,
  CJournalLead,
  CJournalText,
  CBtnList,
  LWrap,
  CProfileMedia,
  CQuoteBox,
  CSingleImg,
  CSingleImg02,
  CChapterHeading,
  CTileImg,
  CLabelTextList,
  AssetImage,
} from "../../../components/_index";
import '../../../assets/_sass/page/journal/journal11.scss';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;
  console.log(data.markdownRemark);
  return (
    <Layout isKv={false}>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CBreadCrumb
        data={{
          parent: [
            {
              label: "pickup",
              path: "/pickup/",
            },
          ],
          current: {
            label: frontmatter?.title.includes("-")
              ? frontmatter?.title.split("-")[0]
              : frontmatter?.title,
          },
        }}
      />
      <CJournalJumbotron
        data={{
          label: "ロイヤルパークホテルズ",
          date: "2024年08月30日",
          title: (
            <>
              丸の内、八ヶ岳、そして全国へ。
              <br/>
              都市と地方の想いをつなぐ、サステナブルな野菜スープが生まれるまで
            </>
          ),
          text: "ロイヤルパークホテルズ",
          img: {
            src: "/assets/images/journal/article12/kv.png",
          },
          imgSp: {
            src: "/assets/images/journal/article12/kv__sp.png",
          },
        }}
      />
      <div className="u_pb60">
        <LWrap size="small">
          <CJournalLead
            lead={
              <>
                <p>
                野菜そのものの旨みが、とろけるように身体に染みわたる―。
                <br />
                ロイヤルパークホテルズで2024年9月1日～10月31日の期間限定で提供されているオニオンとトウモロコシ、ふたつのスープ。
                </p>
                <p>
                素材は八ヶ岳の有機農家が大切に育てた野菜たちだが、実はその豊かな風味に、東京の中心にある皇居外苑濠で増えすぎてしまった水草「ヒシ（菱）」がひと役買っている。
                </p>
                <p>
                皇居外苑濠の環境再生に携わる人、土からこだわり野菜を育む農家、そして心に残る味わいを引き出すシェフ。都市と地方を循環のバトンでつなぐ「サステナブルな野菜スープ」のプロジェクト。関わる人たちの想いを追った。
                </p>
              </>
            }
          />
        </LWrap>
      </div>
      <section className="u_mbLarge">
        <CChapterHeading
          data={{
            label: {
              en: "CHAPTER",
              number: "01",
            },
            title: (
              <>
                <span className="u_pr0_pc">皇居外苑濠で増えすぎた</span>
                <span>水草に、新しい役割を</span>
              </>
            ),
          }}
        />
        <LWrap size="small">
          <CJournalText
            data={{
              text: (
                <>
                  <p>
                  野菜スープの物語が始まるのは、東京。豊かな緑に包まれた皇居外苑濠だ。
                  <br />
                  大手町・丸の内・有楽町と、日本を代表するビジネス街のそばにある生物多様性の宝庫として、ここは都市で働く人たちが喧騒から離れ、ゆったりと過ごせる憩いの場になっている。
                  </p>
                  <p>
                  しかし、この穏やかな水辺空間は長年ある問題に悩まされていた。夏場になると、水草の一種である「ヒシ」が繁茂し、水面を覆い尽くすほどになってしまうのだ。
                  </p>
                </>
              ),
            }}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article12/img_chapter01_01.png",
              alt: "",
            }}
            caption={
            <>
              ヒシは決して悪者ではなく、水辺に暮らす小さな生物の隠れ家、という大切な役割を持っている。
              <br />
              しかし、あまりに増えすぎると水底に日の光が届かなくなり、生態系のバランスを崩してしまう。
            </>
            }
          />
          <CJournalText
            data={{
              text: (
                <>
                  <p>
                  この問題に取り組んで来たのが、ロイヤルパークホテルズのファミリーである三菱地所。環境省と協力して、皇居外苑濠の水辺の生態系を復元し、生物多様性を守ることを目指した「濠プロジェクト」を進めてきた。
                  </p>
                </>
              ),
            }}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article12/img_chapter01_02.png",
              alt: "",
            }}
            caption={
            <>
              三菱地所と環境省が協定を結び、皇居外苑濠を中心とした水辺環境の復元と、
              <br />
              生物多様性の保全を目指して活動する「濠プロジェクト」。
              <br />
              活動に参加しているメンバーの中には、大手町・丸の内・有楽町エリアで働くオフィスワーカーの姿も。
            </>
            }
          />
          <CJournalText
            data={{
              text: (
                <>
                  これらの水辺環境を守る活動から生まれたある閃きが、野菜スープの誕生につながってゆく。三菱地所サステナビリティ推進部の長町真理子氏は、当時のことをこう語る。
                </>
              ),
            }}
          />
          <CProfileMedia
            reverse={true}
            image={{
              img: {
                src: "/assets/images/journal/article12/img_chapter01_03.png",
              },
              imgSp: {
                src: "/assets/images/journal/article12/img_chapter01_03__sp.png",
              },
            }}
            alt={"三菱地所株式会社 サステナビリティ推進部プロモーションユニット 業務主任 長町真理子 氏"}
            company={<>三菱地所株式会社</>}
            officer={<>サステナビリティ推進部プロモーションユニット 業務主任</>}
            name={<>長町 真理子</>}
            honor={"氏"}
            text={
              <>
                「ヒシの堆肥化実験（食農ラボ）」を担当。環境省や農場、
                レストランなど多様な関係者を有機的につなぐパイプ役として活躍している
              </>
            }
          />
          <CJournalText
            data={{
              text: (
                <>
                  <p>
                    「ちょうどその頃、サステナビリティ推進部内では北杜市の有機栽培農家と協働し、都市と地方をつなぐ取り組みができないか、という検討案が持ち上がっていました。そこで、増え過ぎたヒシを植物由来の堆肥にする実験をしてみよう、ということになったんです」 
                  </p>
                  <p>
                    皇居外苑濠のヒシに与えられた新しい役割。三菱地所の想いとともにヒシは山梨・八ヶ岳の大地に運ばれ、有機栽培農家へと託されることになったのだ。
                  </p>
                </>
              ),
            }}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article12/img_chapter01_04.png",
              alt: "",
            }}
          />
        </LWrap>
      </section>

      <section className="u_mbLarge">
        <CChapterHeading
          data={{
            label: {
              en: "CHAPTER",
              number: "02",
            },
            title: (
              <>
                <span className="u_pr0_pc">都市と地方の間に生まれる、</span>
                <span>有機的なつながり</span>
              </>
            ),
          }}
        />
        <LWrap size="small">
          <CJournalText
            data={{
              text: (
                <>
                  山梨県北杜市。八ヶ岳を間近に臨む、標高約900メートルの高原地帯。井上能孝氏が営むファーマンは、農薬や化学肥料を使わない有機農法を実践している農場だ。 
                </>
              ),
            }}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article12/img_chapter02_01.png",
              alt: "",
            }}
          />
          <CJournalText
            data={{
              text: (
                <>
                  広大な敷地の面積は、なんと東京ドーム3.5個分以上。廃棄物を出さずに資源を循環させる「サーキュラーエコノミー」を取り入れ、玉ねぎやニンニクなどの根菜類をメインに、約20品目の野菜を栽培している。このファーマンが、皇居外苑濠から運ばれたヒシに新たな命を吹き込んでいる。
                </>
              ),
            }}
          />
          <CProfileMedia
            reverse={true}
            image={{
              img: {
                src: "/assets/images/journal/article12/img_chapter02_02.png",
              },
              imgSp: {
                src: "/assets/images/journal/article12/img_chapter02_02__sp.png",
              },
            }}
            alt={"株式会社ファーマン井上農場 代表取締役 井上能孝 氏"}
            company={<>株式会社ファーマン井上農場</>}
            officer={<>代表取締役</>}
            name={<>井上 能孝</>}
            honor={"氏"}
            text={
              <>
              高校時代のアメリカへのホームステイを機に農業に関心を持ち、有機農場で働きながら研さんを積む。
              21歳のときに北杜市に移住。地域の福祉施設との連携や企業とのコラボなど、農業の可能性の拡大に取り組んでいる
              </>
            }
          />
          <CJournalText
            data={{
              text: (
                <>
                  <p>
                    一般的に普及しているのは、畜産で出る動物の糞尿を主成分とする動物性の堆肥。しかし、井上氏がヒシを使って取り組んだのは、落ち葉と米ぬかとヒシだけを使った、100％植物性の堆肥づくりだ。
                  </p>
                  <p>
                    「動物性の堆肥は栄養価が高く、野菜が早く育つのを助けるので多くの農家で使われています。一方で植物性堆肥のいいところは、微生物の栄養となる豊富な繊維質。同じ地域、同じ場所で野菜を作り続けても、栄養過多にならずに土の中の環境を整えてくれます。持続可能な農業を考えるうえで、植物性堆肥は大きな可能性を秘めているんです」
                  </p>
                </>
              ),
            }}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article12/img_chapter02_03.png",
              alt: "",
            }}
          />
          <CJournalText
            data={{
              text: (
                <>
                  <p>
                    農場がある山梨県北杜市は農業がさかんな土地だ。八ヶ岳と南アルプスに由来する豊かな水。日本でも有数の長い日照時間。高原ならではの清涼な気候。これらの地形的な特徴は、野菜の「生産」という目線で見ると厳しい環境でもあるという。
                  </p>
                  <p>
                    「昼夜の寒暖差があるこの場所は、ゆっくり野菜が育ちます。効率よく生産性を上げて大量に作る観点で農業を捉えるなら、不利な場所ということになります。一方で、ゆっくり野菜が育つことで味が濃くなり、香りや甘さが引き出されるという良さがある。おいしさで考えるならば最高の環境なんです」
                  </p>
                  </>
              ),
            }}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article12/img_chapter02_04.png",
              alt: "",
            }}
            caption={
              <>
                農場を案内してくれる井上氏。化学物質に頼らない農業生産を行っており、
                <br />
                自然の力で農作物を育成したことを証明する「有機JAS認証」も取得している
              </>
              }
          />
          <CJournalText
            data={{
              text: (
                <>
                 2024年7月、三菱地所のサステナビリティ推進部とロイヤルパークホテルズのシェフたちは、ヒシを使った堆肥作りの現場を知るためにファーマンを視察に訪れていた。つやつやと輝きを放つ玉ねぎを手に、驚きの表情を浮かべる視察メンバー。その様子を見る井上氏にも自然と笑みがこぼれる。
                </>
              ),
            }}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article12/img_chapter02_05.png",
              alt: "",
            }}
          />
          <CJournalText
            data={{
              text: (
                <>
                  目の前の畑でもいだばかりのトウモロコシを食べる体験も。収穫後は時間の経過とともに鮮度が落ちていくのが水分の多い野菜の宿命であるそうだが、たしかに採れたてのトウモロコシは外皮を剥くときに水滴が散るほどのみずみずしさだ。
                </>
              ),
            }}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article12/img_chapter02_06.png",
              alt: "",
            }}
          />
          <CJournalText
            data={{
              text: (
                <>
                  年に何度もファーマンに足を運んでいるという長町氏も、今回がもぎたてトウモロコシの初体験。「生で食べているとは思えないくらい、粒の一つひとつから甘みが広がりますね」と瞳を輝かせながらぱくり。
                </>
              ),
            }}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article12/img_chapter02_07.png",
              alt: "",
            }}
          />
          <CJournalText
            data={{
              text: (
                <>
                  その傍らにあるトウモロコシ畑の中に入っていったのは、ロイヤルパークホテルズの松山昌樹グループ統括総料理長。稲穂のような花を付けながら、空に向かってすっくと立つトウモロコシの間を歩きながら、これぞと思う一本をもぐ。そして、トウモロコシの茎や皮から立ちのぼるもぎたてならではの香りをゆっくりと、嬉しそうに吸い込む。
                </>
              ),
            }}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article12/img_chapter02_08.png",
              alt: "",
            }}
          />
          <CJournalText
            data={{
              text: (
                <>
                  その後は、野菜のレシピ談義に花が咲いた松山総料理長と井上氏。畑をめぐりながら「野菜を無駄にする事なく調理ができる、こんな食べ方もおいしい」などと、楽しそうな意見交換が続いた。
                </>
              ),
            }}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article12/img_chapter02_09.png",
              alt: "",
            }}
            caption={
              <>
                畑に生えるバジルの前で語り合う松山総料理長と、
                <br />
                日焼け対策を兼ねたモダンな作業着姿で向き合う井上氏。
                <br />
                農薬を使わないため、周囲にのびのびと草が生えているのもファーマンの農場の特徴だ
              </>
              }
          />
          <CJournalText
            data={{
              text: (
                <>
                  <p>
                    皇居外苑濠で悩みの種だったヒシが、八ヶ岳の農場で堆肥となり、おいしい野菜を育てる。その野菜はこれから再び都市に運ばれ、スープに生まれ変わるのだ。一見遠く離れた場所同士が、ヒシと野菜を介して結ばれるその姿を通して、井上氏はSDGsの理念を見つめている。
                  </p>
                  <p>
                    「スープを通じて、都市の真ん中にある皇居外苑濠と僕たち地方農場がつながることで、双方向の理解が生まれます。そして農家が消費者のことを考え、消費者が農家のことを思うきっかけにもなり、互いに顔が見える関係性が生まれてくる。SDGsが伝える持続可能性とは、そういうことじゃないかと僕は思うんです」
                  </p>
                  <p>
                    晴れ空の下、この畑に集った都市と地方それぞれの人。
                    <br />
                    目を見て、笑い、ともに汗をかく、そんな有機的なつながりがおいしい野菜を、豊かな自然を守りたいという願いになる。
                    <br />
                    それがいつしか地球環境を守り、豊かな食文化を未来へとつなげていく。
                  </p>
                </>
              ),
            }}
          />
        <CSingleImg
            img={{
              src: "/assets/images/journal/article12/img_chapter02_10.png",
              alt: "",
            }}
          />
        </LWrap>
      </section>

      <section className="u_mbLarge">
        <CChapterHeading
          data={{
            label: {
              en: "CHAPTER",
              number: "03",
            },
            title: (
              <>
                <span className="u_pr0_pc">スープに込める想いが、</span>
                <span>持続可能な社会の架け橋に</span>
              </>
            ),
          }}
        />
        <LWrap size="small">
          <CJournalText
            data={{
              text: (
                <>
                  都市と地方をつなぐ、サステナブルな野菜スープ。最後のバトンを受け取るのは、松山昌樹グループ統括総料理長だ。ヒシ堆肥で育つ野菜を受け取り、ロイヤルパークホテルズの12ホテルで提供するスープにするためのレシピを監修している。
                </>
              ),
            }}
          />
          <CProfileMedia
            reverse={true}
            image={{
              img: {
                src: "/assets/images/journal/article12/img_chapter03_01.png",
              },
              imgSp: {
                src: "/assets/images/journal/article12/img_chapter03_01__sp.png",
              },
            }}
            alt={"三菱地所ホテルズ＆リゾーツ株式会社 グループ統括総料理長 ロイヤルパークホテル（東京・日本橋） 総料理長 兼 調理部長 松山 昌樹 氏"}
            company={<>三菱地所ホテルズ＆リゾーツ株式会社</>}
            officer={<>グループ統括総料理長<br />ロイヤルパークホテル（東京・日本橋）<br />総料理長 兼 調理部長</>}
            name={<>松山 昌樹</>}
            honor={"氏"}
            text={
              <>
              1988年入社、在米国 日本国大使公邸料理長、ザ ロイヤルパークホテル アイコニック東京汐留料理長、
              三菱開東閣料理長を経て、ロイヤルパークホテル総料理長に就任
              </>
            }
          />
          <CJournalText
            data={{
              text: (
                <>
                  <p>
                  農場からバトンを受け取った松山総料理長は何を感じたのだろう。
                  <br />
                  八ヶ岳の農場で大地に根差した野菜作りの現場を見る以前から、ファーマンの育てる野菜の力強い味わいが印象的だった、と松山総料理長は振りかえる。
                  </p>
                  <p> 
                  「有機野菜は健康的で体に良いと認知されていますが、栄養価の高い肥料に頼らず、野菜本来の旨みを引き出し、これほど環境に優しい農業を実践されていることに驚きました」
                  </p>
                </>
              ),
            }}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article12/img_chapter03_02.png",
              alt: "",
            }}
          />
          <CJournalText
            data={{
              text: (
                <>
                  <p>
                  今も手に残る、みずみずしい玉ねぎの重さ。トウモロコシが連なる間を歩いたときの青く力強い香り。そして、ファーマンの野菜に宿る井上氏の想い。八ヶ岳でのひと時は、松山総料理長にとってもかけがえのない経験だったという。
                  </p>
                  <p>
                  スープの素材となるのは、野菜本来の甘みや旨みがたっぷり詰まった玉ねぎとトウモロコシ。味付けは、そんな素材の持ち味を最大限に生かすことに。シンプルな味にすることで、一日のはじまりである朝にふさわしい優しい味わいを追求したのだという。
                  </p>
                </>
              ),
            }}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article12/img_chapter03_03.png",
              alt: "",
            }}
          />
          <CJournalText
            data={{
              text: (
                <>
                  <p>
                  「トウモロコシのスープはさっぱりとした冷製に。あえて粒を残さず滑らかな舌触りに仕上げることで、素材本来の甘みを堪能していただけます。オニオンスープは、少しのバターを加えてソテーし、玉ねぎの深いコクを楽しめる温製にしました」
                  </p>
                  <p>
                  どちらのスープも、朝の心身にじんわりと染み渡るやさしい味。
                  <br />
                  畑で得たような感動を、きっと感じてもらえるはず。こうして松山総料理長のバトンは、お客様に手渡されてゆくのだ。
                  </p>
                </>
              ),
            }}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article12/img_chapter03_04.png",
              alt: "",
            }}
          />
          <CJournalText
            data={{
              text: (
                <>
                  <p>
                    今回のサステナブルな野菜スープを提供するホテルは都市部にある。「都市部で働く料理人だからこそ、地方と都市をつなぐ役割を果たせるのではないか」と松山総料理長は考えている。
                  </p>
                  <p>
                    人口が多く商業がさかんな都市部。豊かな自然がある一方で、少子高齢化などの課題を抱える地方。料理を通して生産地とお客様をつなぎ、都市と地方の共生を促す。総料理長はそれを「都市部で働く料理人の使命」と表現した。
                  </p>
                  <p>
                  「この野菜スープによって、食材の向こうにある産地や農業について想像を巡らせるきっかけになれば嬉しいですよね。食べているものがどこで、どのように育まれたのか。そうした意識が広がることで、食材を大切にする気持ちも芽生えます。それが食品ロスの削減を自分事として捉え行動し、サステナビリティやSDGsの実現につながっていくはずです」
                  </p>
                </>
              ),
            }}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article12/img_chapter03_05.png",
              alt: "",
            }}
          />
          <CJournalText
            data={{
              text: (
                <>
                  <p>丸の内を起点に、八ヶ岳を経て、全国の都市へと広がる野菜スープの物語。
                    <br />
                    その裏側には、持続可能な社会を生み出そうとそれぞれの役割に真摯に向き合う「人」の想いがある。
                  </p>
                  <p>
                    その先には、食を通じて人と地域をつなぎ、サステナブルな循環を生み出すハブとしてのホテル、という新しい可能性が広がっているのではないだろうか。       
                  </p>
                </>
              ),
            }}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article12/img_chapter03_06.png",
              alt: "",
            }}
          />

          <p className="u_tac u_fs20 u_mbSmall">サステナブル野菜スープの提供について</p>
          <CJournalText
            data={{
              text: (
                <>
                <p>
                  提供期間：2024年9月1日（日）～10月31日（木）予定　※無くなり次第終了予定
                  <br />
                  提供場所：
                  <br />
                  ・<a href="https://www.rph.co.jp/" target="_blank">ロイヤルパークホテル（東京・日本橋）</a>
                  <br className="u_sp"/>
                  　シェフズダイニング「シンフォニー」、エグゼクティブラウンジ
                  <br />
                  ・<a href="https://www.yrph.com/" target="_blank">横浜ロイヤルパークホテル</a>
                  <br className="u_sp"/>
                  　フレンチレストラン「ル シエール」、鉄板焼「よこはま」、クラブラウンジ
                  <br />
                  ・<a href="https://www.royalparkhotels.co.jp/ic/tokyoshiodome/" target="_blank">ザ ロイヤルパークホテル アイコニック 東京汐留</a>
                  <br className="u_sp"/>
                  　オールデイダイニング「HARMONY」
                  <br />
                  ・<a href="https://www.royalparkhotels.co.jp/the/ginza6/" target="_blank">ザ ロイヤルパークホテル 銀座6丁目</a>
                  <br className="u_sp"/>
                  　洋食屋「銀座ランプ亭」
                  <br />
                  ・<a href="https://www.royalparkhotels.co.jp/the/tokyohaneda/" target="_blank">ザ ロイヤルパークホテル 東京羽田</a>
                  <br className="u_sp"/>
                  　レストラン&バー「テイルウィンド」
                  <br />
                  ・<a href="https://www.royalparkhotels.co.jp/canvas/sapporoodoripark/" target="_blank">ザ ロイヤルパーク キャンバス 札幌大通公園</a>
                  <br className="u_sp"/>
                  　CANVASラウンジ「KOKAGE」
                  <br />
                  ・<a href="https://www.canvas-ginza8.jp/" target="_blank">ザ ロイヤルパーク キャンバス 銀座8</a>
                  <br className="u_sp"/>
                  　レストラン「Opuses」
                  <br />
                  ・<a href="https://www.royalparkhotels.co.jp/canvas/nagoya/" target="_blank">ザ ロイヤルパーク キャンバス 名古屋</a>
                  <br className="u_sp"/>
                  　カジュアルダイニング「ムッシュいとう」
                  <br />
                  ・<a href="https://www.royalparkhotels.co.jp/canvas/kyotonijo/" target="_blank">ザ ロイヤルパーク キャンバス 京都二条</a>
                  <br className="u_sp"/>
                  　CANVASラウンジ
                  <br />
                  ・<a href="https://www.royalparkhotels.co.jp/canvas/osakakitahama/" target="_blank">ザ ロイヤルパーク キャンバス 大阪北浜</a>
                  <br className="u_sp"/>
                  　CANVASラウンジ
                  <br />
                  ・<a href="https://www.royalparkhotels.co.jp/canvas/kobesannomiya/" target="_blank">ザ ロイヤルパーク キャンバス 神戸三宮</a>
                  <br className="u_sp"/>
                  　CANVASラウンジ
                  <br />
                  ・<a href="https://www.marunouchi-hotel.co.jp/" target="_blank">丸ノ内ホテル</a>
                  <br className="u_sp"/>
                  　フレンチレストラン「ポム・ダダン」
                  </p>
                  <p>
                  ご提供方法はホテルにより異なりますが、朝食やラウンジでのご提供、またランチ・ディナーの一品などとしてご提供いたします。
                  </p>
                </>
              ),
            }}
          />
        </LWrap>
      </section>

      <div className="l_sect02 u_bgGray">
        <LWrap>
          <CBtnList
            data={[
              {
                label: "ロイヤルパークホテルズJournal",
                link: {
                  href: "/pickup/#journal",
                },
                iconBefore: true,
                icon: "arrowReverse",
              },
            ]}
          />
        </LWrap>
      </div>
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
